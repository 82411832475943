<template>
<div class="tournament-join">
      <Loader v-if="loading" loadingtext="Vänta..."></Loader>
      <div v-else>
            <div class="text-center">
                  <h2>Delta i turnering?</h2>
                  <p class="font-italic">Vill du delta i {{tournament.name}}? </p>
            </div>
            <div class="tournament tournament-front">
                  <b-row class="text-center text-sm-left">
                        <b-col cols="12" sm="3">
                              <b-img class="icon" :src="tournamentImage"></b-img>
                        </b-col>
                        <b-col class="mt-3 mt-sm-0" cols="12" sm="9">
                              <h4>{{tournament.name}}</h4>
                              <p class="font-italic d-none d-sm-block">{{tournament.description}}</p>
                        </b-col>
                  </b-row>
            </div>
      </div>
</div>
</template>

<script>
const Loader = () => import("@/components/miscellaneous/Loader");

export default {
      name: 'JoinTournamentConfirm',
      props: ["tournament"],
      components: {
            Loader
      },
      computed: {
            loading() {
                  return this.$store.getters["tournamentsp/joinloading"];
            },
            tournamentImage() {
                  return this.$images.tournamentImage(this.tournament.uuid, this.tournament.imgversion);
            }
      },
      methods: {}
};
</script>
